
import Vue from "vue";
import ShareDropdown from "@/components/ShareDropdown.vue";
import SessionCertificateData from "@/components/Certificate/SessionCertificateData.vue";
import { mapGetters } from "vuex";
//@ts-ignore
import html2pdf from "html2pdf.js";

export default Vue.extend({
  name: "SessionCertificatePage",
  components: {
    SessionCertificateData,
    ShareDropdown
  },
  data: function() {
    return {
      urlBase64: "" as any,
      url: `${process.env.VUE_APP_HOST_URL}/sessions/${this.$route.params.id}/certificate`
    };
  },
  computed: {
    ...mapGetters({
      certificate: "session/getCertificate",
      settings: "certificates/getSettings",
      profile: "profile/getProfile"
    })
    /* async letterhead(){
      const data = await this.toDataUrl(this.settings.certificate_session_letter_head ?  this.settings.certificate_session_letter_head : require('@/assets/icons/session-certificate.svg'));
      console.log('base 64', data);
      return data;
    },*/
  },
  mounted() {
    this.$store.dispatch("certificates/GET_SETTINGS");
    this.getCertificate();
  },
  methods: {
    getCertificate() {
      this.$store
        .dispatch("session/GET_CERTIFICATE", {
          id: this.$route.params.id
        })
        .then(data => {
          console.log("GET_CERTIFICATES>>>>", data);
        });
    },

    async printThis() {
      console.log(
        "this.certificate.certificate_download_path",
        this.certificate.certificate_download_path,
        this.urlBase64
      );

      const seletor = "certificate";
      const element = document.getElementById(seletor);

      const options = {
        margin: 0,
        enableLinks: true,
        filename: "Certificate",
        image: {
          type: "jpeg",
          quality: 1
        },
        html2canvas: {
          scale: 2,
          useCORS: true,
          dpi: 96,
          letterRendering: true,
          y: 0,
          scrollY: 0
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait"
        }
      };

      html2pdf()
        .set(options)
        .from(element)
        .toPdf()
        .save();
    }
  }
});
